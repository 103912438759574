import React from 'react'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import Header from './components/Header'
import Main from './components/Main'
import AppScss from './App.module.scss'

class App extends React.Component {
  render() {
    const theme = createTheme({
      palette: {
        primary: {
          main: '#3abc95',
          contrastText: '#fff'
        }
      }
    })
    return (
      <ThemeProvider theme={theme}>
        <Header />
        <div className={AppScss.App}>
          <Main></Main>
        </div>
      </ThemeProvider>
    )
  }
}

export default App;
