import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SelectButtonTemplate from './SelectButtonTemplate'
import { WATTS } from './Main'
import Grid from '@mui/material/Grid'

type Props = {
  watt: number,
  onWattChange: (watt: number) => void,
  onWattClose: () => void,
  wattDrawer: boolean,
}

class MyMicrowaveOvenCard extends React.Component<Props> {
    render() {
      return (
        <React.Fragment>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>あなたの電子レンジのワット数</Typography>
                <Grid container spacing={3}>
                  <Grid item>
                    <div style={{
                      display: 'flex',
                      alignItems: 'baseline'
                    }}>
                      <Typography sx={{ fontSize: "2.25rem" }}>{this.props.watt}</Typography>
                      <Typography sx={{ fontSize: "1.5rem" }}>W</Typography>
                    </div>
                  </Grid>
                </Grid>
            </CardContent>
          </Card>
          <SelectButtonTemplate
            title={'あなたの電子レンジのワット数を選択してください。'}
            values={WATTS}
            unit={'W'}
            drawer={this.props.wattDrawer}
            onSelect={this.props.onWattChange}
            onClose={this.props.onWattClose}/>
        </React.Fragment>
      )
    }
}

export default MyMicrowaveOvenCard
