import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SelectButtonTemplate from './SelectButtonTemplate'
import { WATTS, MINUTES, SECONDS } from './Main'
import Grid from '@mui/material/Grid'

type Prop = {
  watt: number,
  minutes: number,
  seconds: number,
  onWattChange: (watt: number) => void,
  onMinutesChange: (minutes: number) => void,
  onSecondsChange: (seconds: number) => void,
  onWattClose: () => void,
  onMinutesClose: () => void,
  onSecondsClose: () => void,
  wattDrawer: boolean,
  minutesDrawer: boolean,
  secondsDrawer: boolean,
}

class MicrowaveOvenCard extends React.Component<Prop> {
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>ラベルに表示されたワット数と時間</Typography>
            <Grid container spacing={3}>
              <Grid item>
                <div style={{
                  display: 'flex',
                  alignItems: 'baseline'
                }}>
                  <Typography sx={{ fontSize: "2.25rem" }}>{this.props.watt}</Typography>
                  <Typography sx={{ fontSize: "1.5rem" }}>W</Typography>
                </div>
              </Grid>
              <Grid item xs>
                <div style={{
                  display: 'flex',
                  alignItems: 'baseline'
                }}>
                  <Typography sx={{ fontSize: "2.25rem" }}>{this.props.minutes}</Typography>
                  <Typography sx={{ fontSize: "1.5rem" }}>分&nbsp;</Typography>
                  <Typography sx={{ fontSize: "2.25rem" }}>{this.props.seconds}</Typography>
                  <Typography sx={{ fontSize: "1.5rem" }}>秒</Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <SelectButtonTemplate
          title={'ラベルに表示されたワット数を選択してください。'}
          values={WATTS}
          unit={'W'}
          drawer={this.props.wattDrawer}
          onSelect={this.props.onWattChange}
          onClose={this.props.onWattClose}/>
        <SelectButtonTemplate
          title={'ラベルに表示された温め時間(分)を選択してください。'}
          values={MINUTES}
          unit={'分'}
          drawer={this.props.minutesDrawer}
          onSelect={this.props.onMinutesChange}
          onClose={this.props.onMinutesClose}/>
        <SelectButtonTemplate
          title={'ラベルに表示された温め時間(秒)を選択してください。'}
          values={SECONDS}
          unit={'秒'}
          drawer={this.props.secondsDrawer}
          onSelect={this.props.onSecondsChange}
          onClose={this.props.onSecondsClose}/>
      </React.Fragment>
    )
  }
}

export default MicrowaveOvenCard
